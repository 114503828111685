import React, { useState } from 'react';
import { Modal } from './modal';
import BoldTitle from './BoldTitle';
import { PlayIcon } from '../custom-icons';

const AboutPageTopBanner = () => {
  const [play, setPlay] = useState(false);
  const url = play
    ? `https://www.youtube.com/embed/lHnYyw18vDs?si=b7nDyoEiUl8rTt4b&autoplay=1`
    : `https://www.youtube.com/embed/lHnYyw18vDs?si=b7nDyoEiUl8rTt4b`;

  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-6xl md:grid md:grid-cols-2 md:pt-16 md:pb-20">
        <div className="p-6 md:order-2 lg:min-h-[420px]">
          {play && (
            <Modal
              isOpen={play}
              onClose={() => setPlay(false)}
              className="max-w-3xl"
            >
              <iframe
                className="w-full border-0 rounded-lg aspect-video"
                src={url}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowfullscreen="allowfullscreen"
              />
            </Modal>
          )}
          <div className={`relative h-full w-full`}>
            <div className="absolute inset-0 z-10 m-auto transform -translate-y-1/2 w-max h-max">
              <div className="relative flex w-max h-max">
                <div className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-primary-3" />
                <button
                  className="z-10 w-16 h-16 rounded-full shadow-xl lg:w-20 lg:h-20 bg-primary-2"
                  onClick={() => setPlay(!play)}
                >
                  <PlayIcon className="w-8 h-8 m-auto sm:w-10 sm:h-10 lg:w-12 lg:h-12 text-neutral-7" />
                </button>
              </div>
            </div>
            <img src="/images/about-us/hero-image.svg" alt="brand hero image" />
          </div>
        </div>
        <div className="flex items-center p-6 md:order-1">
          <div>
            <BoldTitle className="mb-6 leading-snug">
              <p> Bring People</p>
              <p className="md:mt-3 text-primary">Closer To Allah</p>
            </BoldTitle>
            <div className="text-sm leading-7 text-center lg:max-w-sm font-regular md:text-left md:pr-4">
              <p>
                <span className="font-bold">
                  Greentech Apps Foundation (GTAF)
                </span>{' '}
                is a UK-based charity with a vision to “Bring people closer to
                Allah”. We aim to{' '}
                <span className="font-bold">
                  help Muslims better understand Islam.
                </span>
              </p>
              <br />
              <p>
                With the vision and mission in mind, we have been focused on
                building{' '}
                <span className="font-bold">
                  user-friendly mobile applications
                </span>{' '}
                since our journey began in 2015.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPageTopBanner;
