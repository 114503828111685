import React from 'react';

import SubscriptionForm from './SubscriptionForm';
import BoldTitle from './BoldTitle';

function SubscribeBlock() {
  return (
    <div className="w-full bg-primary-0">
      <BoldTitle className="pt-16 mb-6 md:text-center md:pt-24">
        Stay Up To Date
      </BoldTitle>
      <div className="px-6 mb-10 text-center font-regular">
        Don&apos;t miss our latest updates & releases
      </div>
      <SubscriptionForm inLine={true} />
    </div>
  );
}

export default SubscribeBlock;
