import React from 'react';
import BoldTitle from './BoldTitle';
import PropTypes from 'prop-types';
import { formatStringWithDoubleLineBreaks } from '../utils';
import IconComponent from './IconComponent';

const TeamMemberCard = ({ image, name, role, description, socialLinks }) => {
  const socialMediaIcons = [
    { key: 'linkedIn', icon: 'linkedin-icon' },
    { key: 'twitter', icon: 'twitter' },
  ];

  return (
    <div className="p-6 shadow-xl bg-neutral rounded-2xl min-h-[262px]">
      <div className="flex items-end gap-6 mb-4">
        <div>
          <img
            className="w-32 mx-auto rounded-md"
            src={image}
            alt={`Image Of ${name}`}
          />
        </div>
        <div className="w-full pb-5 border-b-2 border-neutral-2">
          <p className="text-base font-bold md:text-xl">{name}</p>
          <p className="mb-3 text-sm md:text-base">{role}</p>
          <div className="flex gap-3">
            {socialMediaIcons.map((socialMedia) => {
              const link = socialLinks[socialMedia.key];
              return (
                link && (
                  <a
                    key={socialMedia.key}
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center p-1 rounded text-neutral bg-primary hover:bg-primary-900"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <IconComponent
                      className="w-4 h-4"
                      name={socialMedia.icon}
                    />
                  </a>
                )
              );
            })}
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm">
          {formatStringWithDoubleLineBreaks(description)}
        </p>
      </div>
    </div>
  );
};

TeamMemberCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  socialLinks: PropTypes.object.isRequired,
};
const MeetTheTeamSection = ({ teamMembers }) => {
  return (
    <div className="w-full ">
      <div className="w-full max-w-6xl px-6 py-16 m-auto md:py-24">
        <BoldTitle className="leading-snug">Meet the People Behind</BoldTitle>
        <div className="grid w-full grid-cols-1 gap-6 my-8 md:gap-8 md:grid-cols-2 md:my-16">
          {teamMembers.map((member) => {
            return <TeamMemberCard key={member.id} {...member} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default MeetTheTeamSection;

MeetTheTeamSection.propTypes = {
  teamMembers: PropTypes.array.isRequired,
};
