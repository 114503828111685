import React, { useState } from 'react';
import PropTypes from 'prop-types';

const REQUEST_STATES = {
  none: 'none',
  requested: 'requested',
  failed: 'failed',
  success: 'success',
};

const MainFormUI = ({
  onFormSubmit,
  changeEmail,
  email,
  requestState,
  inLine,
}) => {
  const showForm = !inLine
    ? requestState !== REQUEST_STATES.success &&
      requestState !== REQUEST_STATES.requested
    : true;
  return (
    <div>
      {showForm && (
        <form onSubmit={onFormSubmit}>
          <div
            className={`${
              inLine
                ? 'flex w-full max-w-lg px-6 pb-16 mx-auto md:pb-28 md:px-0'
                : 'w-full my-6'
            }`}
          >
            <input
              className={`${
                inLine
                  ? 'w-full py-5 border-transparent rounded-l-lg shadow-2xl focus:border-transparent focus:ring-0 font-regular placeholder-neutral-5 bg-neutral h-14 text'
                  : 'w-full px-3 py-4 my-6 shadow-xl rounded-xl placeholder:text-neutral-4 md:w-3/4 border-neutral border-1'
              }`}
              required
              type="email"
              placeholder="Enter your email"
              onChange={(e) => changeEmail(e.target.value)}
              value={email}
            />
            <button
              type="submit"
              className={`${
                inLine
                  ? 'px-4 text-sm rounded-r-lg shadow-2xl bg-secondary w-28 md:w-36 text-neutral'
                  : 'w-full py-3 font-bold text-center rounded-full bg-primary text-neutral md:w-2/6'
              }`}
              disabled={
                requestState === REQUEST_STATES.requested ||
                requestState === REQUEST_STATES.success
              }
            >
              Subscribe
            </button>

            {inLine && requestState.success && <p>Successfully Subscribed!</p>}
          </div>
        </form>
      )}
    </div>
  );
};

function SubscriptionForm({ inLine, onSuccess, onFailed }) {
  const [email, changeEmail] = useState('');
  const [requestState, setRequestState] = useState(REQUEST_STATES.none);

  const onFormSubmit = (e) => {
    e.preventDefault();
    setRequestState(REQUEST_STATES.requested);
    fetch(`https://data.gtaf.org/api/proxy/sendy/subscribe/`, {
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify({
        email: email,
        list: 'gkEaNDEGVYKhS0Y0X0szcg',
      }),
    })
      .then((response) => {
        if (response.ok) {
          setRequestState(REQUEST_STATES.success);
          if (onSuccess) onSuccess();
        } else {
          setRequestState(REQUEST_STATES.failed);
          if (onFailed) onFailed();
        }
      })
      .catch(() => {
        setRequestState(REQUEST_STATES.failed);
      });
  };

  return (
    <div>
      <MainFormUI
        inLine={inLine}
        onFormSubmit={onFormSubmit}
        changeEmail={changeEmail}
        email={email}
        requestState={requestState}
      />
    </div>
  );
}

export default SubscriptionForm;

SubscriptionForm.propTypes = {
  inLine: PropTypes.bool,
  onSuccess: PropTypes.func,
  onFailed: PropTypes.func,
};

MainFormUI.propTypes = {
  inLine: PropTypes.bool,
  onFormSubmit: PropTypes.func.isRequired,
  changeEmail: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  requestState: PropTypes.string.isRequired,
};
