import React from 'react';
import PropTypes from 'prop-types';
import CardSliderComponent, {
  CardIndicator,
  CardSlider,
  ControlsWrapper,
  NavigationButtons,
  SliderCard,
  SliderContent,
  SliderTitle,
} from './CardSliderComponent';
import BoldTitle from './BoldTitle';

const JourneyStorySection = ({ journeyStories }) => {
  return (
    <CardSliderComponent items={journeyStories} title="Our Journey">
      <SliderTitle>
        <BoldTitle className="leading-snug">Our Journey</BoldTitle>
      </SliderTitle>
      <SliderContent>
        <CardSlider>
          {journeyStories.map((item, index) => (
            <SliderCard key={item.id || index}>
              <div className="m-auto ">
                <img
                  className="w-56 h-56 lg:h-36 lg:w-36 "
                  src={item.storyImage}
                  alt={`Icon for ${item.title}`}
                />
              </div>
              <div className="lg:w-96">
                <p className="my-2 mt-1 font-bold md:text-xl">{item.title}</p>
                <p className="text-sm text-neutral-7">{item.description}</p>
              </div>
            </SliderCard>
          ))}
        </CardSlider>
        <ControlsWrapper hasIndicator>
          <CardIndicator items={journeyStories} />
          <NavigationButtons />
        </ControlsWrapper>
      </SliderContent>
    </CardSliderComponent>
  );
};

export default JourneyStorySection;

JourneyStorySection.propTypes = {
  journeyStories: PropTypes.array.isRequired,
};
