import React from 'react';
import BoldTitle from './BoldTitle';

const ourValues = [
  {
    id: 1,
    title: 'Keep growing ✨♻️',
    description:
      "We aim to be the best at what we do and constantly learn and get better. We don't settle for the status quo. We are focused on growth and continuous improvement. We simplify the steps and maintain consistency.",
  },
  {
    id: 2,
    title: 'Make the team Win 💬 🙌',
    description:
      'We are not afraid to share ideas and concerns with others. We prioritize team success over individual accomplishments. We collaborate and share knowledge with the team. We support each other in achieving both team and personal goals.',
  },
  {
    id: 3,
    title: 'Foster Creativity 💡⛰️',
    description:
      'We learn, create, and innovate. We are not afraid to challenge traditional methods. We can adapt to change.',
  },
  {
    id: 4,
    title: 'Be Proactive 🖐️👣',
    description:
      'We are motivated and take the initiative. We prefer collaboration over coercion. We anticipate and resolve issues instead of reacting to them.',
  },
];
const OurValuesSection = () => {
  return (
    <div className="w-full bg-primary-0">
      <div className="w-full max-w-6xl px-6 py-16 m-auto md:py-24">
        <BoldTitle className="leading-snug">Our Values</BoldTitle>
        <div className="grid w-full grid-cols-1 gap-6 mt-8 md:gap-8 md:grid-cols-2 md:mt-16">
          {ourValues.map((item) => {
            return (
              <div
                key={item.id}
                className="w-full p-6 border bg-neutral rounded-2xl border-primary-2"
              >
                <p className="mb-2 font-semibold text-accent">{item.title}</p>
                <p className="text-sm leading-6 md:text-base text-accent-3">
                  {item.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OurValuesSection;
