import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import BoldTitle from './BoldTitle';
import BlogCard, { CARD_VARIANTS } from './BlogCard';

function BlogHighlights({ title, count, featuredBlogs }) {
  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-6xl">
        <BoldTitle className="mt-16 mb-8 md:mt-24 md:mb-12 md:text-center">
          {title}
        </BoldTitle>
        <div
          className={`md:grid md:grid-cols-${count} mt-10 gap-4 lg:gap-11 mb-8 md:mb-24 px-6 sm:px-4`}
        >
          {featuredBlogs.slice(0, count).map((item) => (
            <Link to={`/blog/${item.slug}/`} key={item.slug}>
              <BlogCard
                className="w-full h-full"
                variant={CARD_VARIANTS.SMALL}
                post={item}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

BlogHighlights.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
  featuredBlogs: PropTypes.array.isRequired,
};

BlogHighlights.defaultProps = {
  title: 'From Our Blog',
  count: 3,
};

export default BlogHighlights;
