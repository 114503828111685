import React from 'react';
import PropTypes from 'prop-types';
import BoldTitle from './BoldTitle';

const MissionVisionCard = ({ title, icon, details }) => {
  return (
    <div className="flex items-start justify-between w-full gap-2 p-5 shadow-xl md:p-6 md:gap-6 rounded-2xl">
      <div>
        <div className="mb-3 rounded bg-primary text-neutral font-semiBold w-max md:text-xl">
          <p className="px-3 py-1">{title}</p>
        </div>
        <div className="text-sm md:text-base">{details}</div>
      </div>
      <div>
        <img
          className="h-24 min-w-[96px]"
          src={icon}
          alt={`Icon for ${title}`}
        />
      </div>
    </div>
  );
};

MissionVisionCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
};

const MissionVisionSection = () => {
  const missionVisionData = [
    {
      id: 1,
      title: 'Our Vision',
      details: 'To bring people closer to Allah',
      icon: '/icons/vision.svg',
    },
    {
      id: 2,
      title: 'Our Mission',
      details: 'To help Muslims better understand Islam',

      icon: '/icons/mountain.svg',
    },
  ];
  return (
    <div className="w-full max-w-6xl px-6 py-16 m-auto md:py-24">
      <BoldTitle className="leading-snug">Our Vision and Mission</BoldTitle>
      <div className="flex flex-col w-full gap-6 mt-8 md:gap-8 md:mt-16 md:flex-row">
        {missionVisionData.map((item) => (
          <MissionVisionCard
            key={item.id}
            title={item.title}
            icon={item.icon}
            details={item.details}
          />
        ))}
      </div>
    </div>
  );
};

export default MissionVisionSection;
