import React from 'react';
import BoldTitle from './BoldTitle';
import PropTypes from 'prop-types';

export const AchievementCard = ({ icon, title, description }) => (
  <div className="flex justify-center items-center w-full p-8 py-10 shadow-xl rounded-2xl bg-neutral lg:h-[252px]">
    <div className="h-max">
      <img
        className="w-12 h-12 mx-auto mb-5"
        src={icon}
        alt={`achievement ${title}`}
      />
      <div className="text-xl font-bold text-center">{title}</div>
      <div className="mt-2 text-sm leading-6 text-center max-w-[170px] min-h-[48px]">
        {description}
      </div>
    </div>
  </div>
);

AchievementCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const AchievementsSection = ({ achievements }) => {
  return (
    <div className="w-full bg-primary-0">
      <div className="w-full max-w-6xl px-6 py-16 m-auto md:py-24">
        <BoldTitle className="leading-snug">
          What We Have Achieved So Far
        </BoldTitle>
        <div className="grid w-full grid-cols-1 gap-6 my-8 md:gap-8 sm:grid-cols-2 md:my-16 lg:grid-cols-4">
          {achievements.map((item) => (
            <AchievementCard
              key={item.title}
              title={item.title}
              icon={item.icon}
              description={item.description}
            />
          ))}
        </div>
        <div className="w-full max-w-2xl m-auto text-sm text-center md:text-base">
          <p className="leading-7">
            Alhamdulillah, we now have 10+ apps, millions of users, and more
            than 20 motivated team members. Alongside our favourite flagship
            app, Al Quran (Tafsir & by Word), we have apps for hadith
            Collection, Dua and Seerah. We&apos;re continually enhancing our
            apps to help Muslims get better every day.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AchievementsSection;

AchievementsSection.propTypes = {
  achievements: PropTypes.array.isRequired,
};
